@font-face {
  font-family: "Gotham Book";
  src: url("fonts/Gotham-Book.eot");
  src: url("fonts/Gotham-Book.eot?#iefix") format("embedded-opentype"),
       url("fonts/Gotham-Book.woff2") format("woff2"),
       url("fonts/Gotham-Book.woff") format("woff"),
       url("fonts/Gotham-Book.ttf") format("truetype"),
       url("fonts/Gotham-Book.svg#Gotham-Book") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'sharpsans-bold';
    src: url("fonts/SharpSansNo1-Bold.eot");
    src: url("fonts/SharpSansNo1-Bold.eot?#iefix") format("embedded-opentype"), 
         url("fonts/SharpSansNo1-Bold.woff2") format("woff2"),
         url("fonts/SharpSansNo1-Bold.woff") format("woff"),
         url("fonts/SharpSansNo1-Bold.ttf") format("truetype"),
         url("fonts/SharpSansNo1-Bold.svg#SharpSansNo1-Bold") format("svg");
    font-display: swap;
}