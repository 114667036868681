.vertical-timeline-element-content-arrow,
.vertical-timeline-element-icon {
    display: none;
}

.vertical-timeline-element-content {
    margin-left: 0;
}

@media (min-width:1280px) {
    .vertical-timeline-element-content-arrow,
    .vertical-timeline-element-icon {
        display: block;
    }

    .vertical-timeline-element-content {
        margin-left: 60px;
    }
    
    .vertical-timeline.vertical-timeline-custom-line {
        padding: 0;
    }

    .vertical-timeline.vertical-timeline-custom-line::before {
        background: theme('colors.brand-muted');
    }

    .vertical-timeline-element.--task-complete:first-child {
        margin-top: 0;
    }

    .vertical-timeline-element.--task-complete:last-child {
        margin-bottom: 0;
    }

    .vertical-timeline-element.--task-complete {
        margin: 2rem 0;
    }

    .--task-todo .vertical-timeline-element-content {
        margin-left: 0;
    }

    .--task-complete .vertical-timeline-element-icon {
        background: theme('colors.brand-primary');
        color: #fff;
        width: 50px;
        height: 50px;
        top: 30px;
        left: -5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 0 2px #fff;
        -webkit-box-shadow: 0 0 0 2px #fff;
    }

    .--task-complete .vertical-timeline-element-icon span {
        font-size: 0.7rem;
        text-align: center;
    }
}